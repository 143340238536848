import 'slick-carousel'
import 'floatthead'
 
import $ from 'jquery'
import jQueryBridget from 'jquery-bridget'
import Isotope from 'isotope-layout'

// turn isotope into a jQuery plugin
jQueryBridget('isotope', Isotope, $)

/***********************/
/* START About template horizontal timeline slider
/***********************/
jQuery(document).ready( function($) {
  var $slider = $('.section__about__slider__items').slick({
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: $('.section__about__slider__buttons__left'),
    nextArrow: $('.section__about__slider__buttons__right'),
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  })

  $('.section__about__slider__items').show()

  var scroll,
    speed = 1000

  var stop = function() {
    clearInterval(scroll)
  }

  var rw = function() {
    stop()
    $slider.slick('slickPrev')
    scroll = setInterval(function() {
      $slider.slick('slickPrev')
    }, speed)
  }

  var fw = function() {
    stop()
    $slider.slick('slickNext')
    scroll = setInterval(function() {
      $slider.slick('slickNext')
    }, speed)
  }

  $('body').on('mouseenter', '.section__about__slider__buttons__right', fw)
  $('body').on('mouseenter', '.section__about__slider__buttons__left', rw)
  $('body').on(
    'mouseleave',
    '.section__about__slider__buttons__right, .section__about__slider__buttons__left',
    stop
  )
  /***********************/
  /* END About template horizontal timeline slider
  /***********************/

  
  /***********************/
  /* START Image carousel block
  /***********************/
  $('.image-carousel').each(function() {
    var $carousel = $(this).find('.image-carousel__track');
    var $prevArrow = $(this).find('.image-carousel__arrow-left');
    var $nextArrow = $(this).find('.image-carousel__arrow-right');

    $carousel.slick({
        // centerMode: true,
        variableWidth: true,
        slidesToScroll: 1,
        prevArrow: $prevArrow,
        nextArrow: $nextArrow,
    });

    // Remove the initial left offset when sliding begins
    $carousel.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        $(this).css('margin-left', '0');
    });
  });
  /***********************/
  /* END Image carousel block
  /***********************/


  /***********************/
  /*** BACK TO TOP BTN ***/
  /***********************/

  // browser window scroll (in pixels) after which the "back to top" link is shown
  var offset = 300,
    //browser window scroll (in pixels) after which the "back to top" link opacity is reduced
    offset_opacity = 1200,
    //duration of the top scrolling animation (in ms)
    scroll_top_duration = 700,
    //grab the "back to top" link
    $back_to_top = $('.cd-top')

  //hide or show the "back to top" link
  $(window).scroll(function() {
    $(this).scrollTop() > offset
      ? $back_to_top.addClass('cd-is-visible')
      : $back_to_top.removeClass('cd-is-visible cd-fade-out')
    if ($(this).scrollTop() > offset_opacity) {
      $back_to_top.addClass('cd-fade-out')
    }
  })

  //smooth scroll to top
  $back_to_top.on('click', function(event) {
    event.preventDefault()
    $('body,html').animate(
      {
        scrollTop: 0,
      },
      scroll_top_duration
    )
  })

  /****************************/
  /*** BACK TO TOP BTN END ***/
  /***************************/

  /*******************************/
  /*** Smooth Scroll To Anchor ***/
  /*******************************/

  $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#book-a-demo"]')
    .not('[href="#0"]')
    .not('#modal-click')
    .not('.modal-click')
    .not('.no-scroll')
    .not('.anchor-nav__item-link')
    .click(function(event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, '') ==
          this.pathname.replace(/^\//, '') &&
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash)
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']')
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault()

          $('html, body').animate(
            {
              scrollTop: target.offset().top - 110,
            },
            1000,
            function() {}
          )
        }
      }
    }
  )

  /***********************************/
  /*** Smooth Scroll To Anchor END ***/
  /***********************************/

 

  $('.center').slick({
    centerMode: false,
    centerPadding: '60px',
    slidesToShow: 3,
    arrows: false,
    dots: true,

    responsive: [
      {
        breakpoint: 769,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1,
          fade: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1,
          fade: true,
        },
      },
    ],
  })

    /*******************************************************/
    /*** LOAD DEFERRED YOUTUBE EMBEDS ON CLICK ***/
    /*******************************************************/
    $('.video-container').click(function () {

        var videoUrl = $(this).find("img").data("src");

        $(this).html('<iframe id="" width="560" height="315" frameborder="0" src="//www.youtube.com/embed/' + videoUrl + '?autoplay=1" allowfullscreen></iframe>');

    });

  
});
