// docReady utility function
function docReady(fn) {//IE9+
	// see if DOM is already available
	if (document.readyState === "complete" || document.readyState === "interactive") {
		// call on next available tick
		setTimeout(fn, 1);
	} else {
		document.addEventListener("DOMContentLoaded", fn);
	}
}

docReady(function () {//Above IE8
	if(document.querySelector('.roi-calculator')){
		roiBlock = document.querySelector('.roi-calculator');

		// Global flag to track initialization
		let calculationInitialized = false;

		// Global variable for selected currency
		let selectedCurrency = 'usd';

		//// Progress bar
		let progressNode1 = document.querySelector("#progress-node--1");
		let progressNode2 = document.querySelector("#progress-node--2");
		let progressNode3 = document.querySelector("#progress-node--3");

		//// Dropdown elements
		let dropdownBtn = document.getElementById('js-roi-currency');
		let dropdownList = document.querySelector('.custom-dropdown__list');
		let dropdownItems = document.querySelectorAll('.custom-dropdown__item');
		let dropdownBtnText = dropdownBtn.querySelector('.custom-dropdown__text');
		let dropdownBtnIcon = dropdownBtn.querySelector('.custom-dropdown__icon');
		let outputCurrencySymbols = document.querySelectorAll(".currency-symbol");

		//// Input elements
		let monthlyOrdersInputElement = document.querySelector("#js-roi-orders");
		let averageOrderValueInputElement = document.querySelector("#js-roi-aov");
		let annualPurchaseFrequencyInputElement = document.querySelector("#js-roi-annual");
		let lifetimePurchasesInputElement = document.querySelector("#js-roi-lifetime");
		let proceedButton = document.querySelector("#js-roi-proceed");
		let proceedMessageElement = document.querySelector(".roi-calculator__proceed-message");
		let unlockCTAMessageElement = document.querySelector(".roi-calculator__unlock-cta-text");

		//// Panels
		let inputsPanel = document.querySelector(".roi-calculator__inputs");
		let outputsPanel = document.querySelector(".roi-calculator__outputs");
		let formPanel = document.querySelector(".roi-calculator__form");
		let metricsPanel = document.querySelector(".roi-calculator__metrics");
		let blurElements = document.getElementsByClassName('blur');

		//// Output elements
		let totalRevenueOutput = document.querySelector("#js-roi-revenue");
		let returnOnInvestmentOutput = document.querySelector("#js-roi-roi");
		let newRedeemersOutput = document.querySelector("#js-roi-new-redeemers");
		let newRepeatPurchasesOutput = document.querySelector("#js-roi-new-repeats");
		let multiplierSymbol = document.querySelector(".multiplier");


		//// Function to parse URL query parameters
		function getUrlParameter(name) {
			name = name.replace(/[\[\]]/g, "\\$&");
			var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
			var results = regex.exec(window.location.href);
			if (!results) return null;
			if (!results[2]) return '';
			return decodeURIComponent(results[2].replace(/\+/g, " "));
		}

		// Check if there are query parameters for the inputs and set their values
		if (getUrlParameter("monthly")) {
			monthlyOrdersInputElement.value = parseFloat(getUrlParameter("monthly").replace(/,/g, ''));
			averageOrderValueInputElement.value = parseFloat(getUrlParameter("average").replace(/,/g, ''));
			annualPurchaseFrequencyInputElement.value = parseFloat(getUrlParameter("annual"));
			lifetimePurchasesInputElement.value = parseFloat(getUrlParameter("lifetime"));

			let currencyParam = getUrlParameter("currency");
			
			// Update the global selected currency var, change dropdown + symbols
			selectedCurrency = currencyParam;
			updateDropdownSelection(currencyParam);
			updateCurrencySymbols(currencyParam);

			// Update the progress bar for the first step
			progressNode1.classList.add("completed");
			progressNode2.classList.add("active");

			// Update the proceed button
			proceedButton.classList.add("roi-calculator__proceed--unlock");
			proceedButton.textContent = "Unlock advanced metrics";
			unlockCTAMessageElement.classList.remove("hidden");

			// Mark calculation as initialized
			calculationInitialized = true;

			calculateROI();

			// Listen for new input changes
			document.querySelectorAll(".roi-calculator__input").forEach(function(element) {
				element.addEventListener("input", function() {
					calculateROI();
				});
			});

			// un-blur everything if there's a url param with the relevant value
			if (getUrlParameter("insights") && getUrlParameter("insights") == 'on') {

				// Update progress bar
				progressNode2.classList.add("completed");
				progressNode3.classList.add("active");

				// Hide calc submit button
				proceedButton.classList.add("roi-calculator__proceed--hidden");
				unlockCTAMessageElement.classList.add("hidden");
				
				// Replace form panel html
				formPanel.innerHTML = `
					<div class="roi-calculator__form-submitted-message">
						<p>Ready to start working towards these metrics?</p>
						<a href="https://loyaltylion.com/demo" class="block-button primary has-purple-background-color" target="_self" rel="noopener">Talk to sales</a>
					</div>
				`;

				// Reveal form + metrics
				inputsPanel.classList.add("roi-calculator__inputs--fixed-height");
				outputsPanel.classList.add("roi-calculator__outputs--fixed-height");
				formPanel.classList.add("active");
				metricsPanel.classList.add("active");
				for (var i = 0; i < blurElements.length; i++) {
					blurElements[i].classList.remove('blur--active');
				}
			}
		}

		/**
		 * Handle the calcs
		 */
		function calculateROI() {

			const exchangeRates = {
				usd: 1,
				gbp: 0.8,
				eur: 0.96
			};

			function convertToCurrency(amount, currency) {
				const rate = exchangeRates[currency] || 1; // Default to USD if no valid currency is found
				return amount * rate;
			}

			let	softwareFeeClassic = convertToCurrency(399, selectedCurrency);
			let	softwareFeeAdvanced = convertToCurrency(729, selectedCurrency);
			let	softwareFeePlus1 = convertToCurrency(1650, selectedCurrency);
			let	softwareFeePlus2 = convertToCurrency(6100, selectedCurrency);
			let	softwareFeePlus3 = convertToCurrency(8300, selectedCurrency);
			let	softwareFeePlus4 = convertToCurrency(12700, selectedCurrency);

			//// Input values (convert user input into numbers, allowing decimals but only using first 2)
			let monthlyOrders = monthlyOrdersInputElement.value.trim()
				? parseFloat(monthlyOrdersInputElement.value.replace(/,/g, '') // Remove commas
					.match(/^-?\d+(\.\d{0,2})?/)[0]) // Keep only up to 2 decimal places
				: null; // If empty, set to null
			let averageOrderValue = averageOrderValueInputElement.value.trim()
				? parseFloat(averageOrderValueInputElement.value.replace(/,/g, '')
					.match(/^-?\d+(\.\d{0,2})?/)[0])
				: null;
			let annualPurchaseFrequency = annualPurchaseFrequencyInputElement.value.trim()
				? parseFloat(annualPurchaseFrequencyInputElement.value.replace(/,/g, '')
					.match(/^-?\d+(\.\d{0,2})?/)[0])
				: null;
			let lifetimePurchases = lifetimePurchasesInputElement.value.trim()
				? parseFloat(lifetimePurchasesInputElement.value.replace(/,/g, '')
					.match(/^-?\d+(\.\d{0,2})?/)[0])
				: null;


			// If any input is empty, clear outputs and return early
			if (
				monthlyOrders === null || isNaN(monthlyOrders) ||
				averageOrderValue === null || isNaN(averageOrderValue) ||
				annualPurchaseFrequency === null || isNaN(annualPurchaseFrequency) ||
				lifetimePurchases === null || isNaN(lifetimePurchases)
			) {
				totalRevenueOutput.textContent = "-";
				returnOnInvestmentOutput.textContent = "-";
				newRedeemersOutput.textContent = "-";
				newRepeatPurchasesOutput.textContent = "-";
				multiplierSymbol.classList.add("hidden");
				return;
			}

			// Restricted metrics graphs
			let donut1 = document.querySelector("#donut-1");
			let donut2 = document.querySelector("#donut-2");
			let donut3 = document.querySelector("#donut-3");
			let donut4 = document.querySelector("#donut-4");

			//// Hardcoded figures
			let percentageDiscountReferredCustomers = 15 / 100;
			let percentageDiscountLoyaltyMembers = 5 / 100;
			let percentageOfMembersBecomingRedeemers = 0.2324;
			let percentageOfShoppersBecomingMembers = 0.3446;
			let revenueInnovativeLoyaltyReferralFeatures = 0;// no value given in spreadsheet
			let costInnovativeLoyaltyReferralFeatures = 0;// no value given in spreadsheet
			let successfullyReferredCustomersAnnually = 250;
			let percentageAnnualSpendRedeemerVersusShopper = 1.6445;
			let percentageAnnualSpendMemberVersusShopper = 0.3997;
			let percentageOrdersByMembersInvolvingReward = 0.33232649026217487;
			let percentagePurchaseFreqOfMemberAboveShopper = 0.2806;


			let costSoftwareFee = softwareFeeClassic * 12;
			if (monthlyOrders > 199999) {
				costSoftwareFee = softwareFeePlus4 * 12;
			} else if (monthlyOrders > 99999) {
				costSoftwareFee = softwareFeePlus3 * 12;
			} else if (monthlyOrders > 49999) {
				costSoftwareFee = softwareFeePlus2 * 12;
			} else if (monthlyOrders > 9999) {
				costSoftwareFee = softwareFeePlus1 * 12;
			} else if (monthlyOrders > 3999) {
				costSoftwareFee = softwareFeeAdvanced * 12;
			}

			// console.group(`Calculating ROI in ${selectedCurrency} currency`);
			// console.log(`Monthly Advanced Fee: ${softwareFeeAdvanced}`);
			// console.log(`Yearly software Fee: ${costSoftwareFee}`);
			// console.groupEnd();


			//// Calculations
			var annualSpendAllShoppers = averageOrderValue * annualPurchaseFrequency;
			var annualSpendMember = annualSpendAllShoppers * ( 1 + percentageAnnualSpendMemberVersusShopper);
			var annualSpendRedeemingMember = annualSpendAllShoppers * ( 1 + percentageAnnualSpendRedeemerVersusShopper);
			var shoppers = (monthlyOrders / annualPurchaseFrequency ) * 12;
			var members = shoppers * percentageOfShoppersBecomingMembers;
			var newRedeemingMembersPerYear = members * percentageOfMembersBecomingRedeemers;
			var revenueCoreLoyalty = (annualSpendRedeemingMember - annualSpendMember) * newRedeemingMembersPerYear;
			var revenueCoreReferrals = successfullyReferredCustomersAnnually * averageOrderValue * lifetimePurchases;
			var costCoreLoyalty = newRedeemingMembersPerYear * annualSpendRedeemingMember * percentageOrdersByMembersInvolvingReward * percentageDiscountLoyaltyMembers;
			var costCoreReferrals = successfullyReferredCustomersAnnually * averageOrderValue * percentageDiscountReferredCustomers;
			
			var totalRevenueGenerated = revenueInnovativeLoyaltyReferralFeatures + revenueCoreLoyalty + revenueCoreReferrals;
			var newRepeatPurchasesPerYear = totalRevenueGenerated / averageOrderValue;
			var totalCosts = costInnovativeLoyaltyReferralFeatures + costCoreLoyalty + costCoreReferrals + costSoftwareFee;
			var returnOnInvestment = totalRevenueGenerated / totalCosts;

			var memberPurchaseFrequency = annualPurchaseFrequency * ( 1 + percentagePurchaseFreqOfMemberAboveShopper);	
			var redeemingMemberFrequency = annualPurchaseFrequency * ( 1 + percentageOrdersByMembersInvolvingReward);				
			
			/**
			 * Update the outputs
			*/
			totalRevenueOutput.textContent = !isFinite(totalRevenueGenerated) ? "-" : Math.round(totalRevenueGenerated);
			returnOnInvestmentOutput.textContent = !isFinite(returnOnInvestment) ? "-" : returnOnInvestment.toFixed(2);
			newRedeemersOutput.textContent = !isFinite(newRedeemingMembersPerYear) ? "-" : Math.round(newRedeemingMembersPerYear);
			newRepeatPurchasesOutput.textContent = !isFinite(newRepeatPurchasesPerYear) ? "-" : Math.round(newRepeatPurchasesPerYear);
			if( !isFinite(returnOnInvestment) ) {
				multiplierSymbol.classList.add("hidden");
			} else {
				multiplierSymbol.classList.remove("hidden");
			}

			/**
			 * Update restricted metrics graphs
			*/
			function updateDonut(donutElement, prefix, percentage) {
				// Set the new percentage for the donut fill
				donutElement.style.setProperty('--p', percentage);
				// Add the new percentage to the donut inner text
				const donutFigureElement = donutElement.querySelector('.donut__figure');
				if (donutFigureElement) {
					donutFigureElement.textContent = prefix + percentage + '%';
				}
			}

			updateDonut(donut1, '', 23);
			updateDonut(donut2, '', 5.9);
			updateDonut(donut3, '+', redeemingMemberFrequency.toFixed(1));
			updateDonut(donut4, '+', 40);

			// Run comma formatting on output 
			formatCommasAndDecimals(totalRevenueOutput);
		}

		// Function to check if all inputs are valid
		function validateInputsAndToggleButton() {
			const monthlyOrders = parseFloat(monthlyOrdersInputElement.value.trim());
			const averageOrderValue = parseFloat(averageOrderValueInputElement.value.trim());
			const annualPurchaseFrequency = parseFloat(annualPurchaseFrequencyInputElement.value.trim());
			const lifetimePurchases = parseFloat(lifetimePurchasesInputElement.value.trim());

			const allInputsValid = 
				monthlyOrders > 0 &&
				averageOrderValue > 0 &&
				annualPurchaseFrequency > 0 &&
				lifetimePurchases > 0;

			if (allInputsValid) {
				// Update global flag + add active class to the button if all inputs are valid
				calculationInitialized = true;
				proceedButton.classList.add("active");
			} else {
				// Remove the active class if any input is invalid
				proceedButton.classList.remove("active");
			}
		}

		// Attach the input event listener to all inputs
		document.querySelectorAll(".roi-calculator__input").forEach(input => {
			input.addEventListener("input", function () {
				formatCommasAndDecimals(input);
				validateInputsAndToggleButton();
			});

			input.addEventListener("focusout", function () {
				inputFormatOnFocusout(input);
			});
		});


		/**
		 * Currency dropdown behaviours
		 */
		// Toggle visibility
		dropdownBtn.addEventListener('click', () => {
			const isExpanded = dropdownBtn.getAttribute('aria-expanded') === 'true';
			dropdownBtn.setAttribute('aria-expanded', !isExpanded);
			dropdownList.hidden = isExpanded;
		});

		// Updates the dropdown button text and icon based on the selected currency
		function updateDropdownSelection(value) {
			const iconClass = `icon--${value}`;
			const dropdownItem = document.querySelector(`[data-value="${value}"]`);
			
			if (dropdownItem) {
				const text = dropdownItem.textContent.trim();
				dropdownBtnText.textContent = text;
				dropdownBtnIcon.className = `custom-dropdown__icon ${iconClass}`;
			}
		}

		// Updates all elements displaying currency symbols based on the selected currency
		function updateCurrencySymbols(value) {
			let currencyCharacter = '$';
			if (value === 'gbp') {
				currencyCharacter = '£';
			} else if (value === 'eur') {
				currencyCharacter = '€';
			}

			outputCurrencySymbols.forEach(symbol => {
				symbol.textContent = currencyCharacter;
			});
		}

		// Handle dropdown item selection
		dropdownItems.forEach(item => {		
			item.addEventListener('click', () => {
				const value = item.getAttribute('data-value');

				// Update dropdown text + icon
				updateDropdownSelection(value);

				// Close dropdown
				dropdownBtn.setAttribute('aria-expanded', 'false');
				dropdownList.hidden = true;
		
				// Update all currency symbol characters
				updateCurrencySymbols(value);
				
				// Update global selected currency
				selectedCurrency = value;

				// Recalculate only if calculation has been initialized
				if (calculationInitialized) {
					calculateROI();
				}

			});
		});

		// Close dropdown on outside click
		document.addEventListener('click', (e) => {
			if (!dropdownBtn.contains(e.target) && !dropdownList.contains(e.target)) {
				dropdownBtn.setAttribute('aria-expanded', 'false');
				dropdownList.hidden = true;
			}
		});

		/**
		 * Proceed button clicks
		 */
		proceedButton.addEventListener("click", function () {
			// Validate inputs
			const monthlyOrders = parseFloat(monthlyOrdersInputElement.value.trim());
			const averageOrderValue = parseFloat(averageOrderValueInputElement.value.trim());
			const annualPurchaseFrequency = parseFloat(annualPurchaseFrequencyInputElement.value.trim());
			const lifetimePurchases = parseFloat(lifetimePurchasesInputElement.value.trim());

			if (
				isNaN(monthlyOrders) || monthlyOrders <= 0 ||
				isNaN(averageOrderValue) || averageOrderValue <= 0 ||
				isNaN(annualPurchaseFrequency) || annualPurchaseFrequency <= 0 ||
				isNaN(lifetimePurchases) || lifetimePurchases <= 0
			) {
				// Show the message and disable button if any input is missing or <= 0
				proceedMessageElement.classList.remove("hidden");
				proceedButton.classList.remove("roi-calculator__proceed--unlock");
				proceedButton.classList.remove("active");
				proceedButton.textContent = "Calculate";
			} else {
				// Hide the message if all inputs are > 0
				proceedMessageElement.classList.add("hidden");

				if (proceedButton.classList.contains("roi-calculator__proceed--unlock")) {
					// When the button already has the "unlock" class and inputs are valid
					proceedButton.classList.add("roi-calculator__proceed--hidden");
					unlockCTAMessageElement.classList.add("hidden");
					
					// Reveal form + blurred metrics
					inputsPanel.classList.add("roi-calculator__inputs--fixed-height");
					outputsPanel.classList.add("roi-calculator__outputs--fixed-height");
					formPanel.classList.add("active");
					metricsPanel.classList.add("active");

				} else {
					// Run the calculation for the first time
					calculateROI();

					document.querySelectorAll(".roi-calculator__input").forEach(function(element) {
						element.addEventListener("input", function() {
							calculateROI();
						});
					});

					// Update the button
					proceedButton.classList.add("roi-calculator__proceed--unlock");
					proceedButton.textContent = "Unlock advanced metrics";
					unlockCTAMessageElement.classList.remove("hidden");

					// Update the progress bar for the first step
					progressNode1.classList.add("completed");
					progressNode2.classList.add("active");
				}
			}
		});



		/**
		 * Copy your results
		 */
		let calcShareButton = document.querySelector("#calculatorCopy");

		calcShareButton.addEventListener("click", function () {
			// Get the current page permalink
			const permalink = calcShareButton.getAttribute("data-url");

			// Detect state of gated 'detailed metrics' insights
			const insightsState = calcShareButton.getAttribute("data-insights");

			// Create URL parameters for each of the four inputs + currency
			let monthlyOrders = parseFloat(document.querySelector("#js-roi-orders").value.replace(/,/g, ''));
			let averageOrderValue = parseFloat(document.querySelector("#js-roi-aov").value.replace(/,/g, ''));
			let annualPurchaseFrequency = parseFloat(document.querySelector("#js-roi-annual").value);
			let lifetimePurchases = parseFloat(document.querySelector("#js-roi-lifetime").value);

			const utmParamString = '&utm_source=roi_calculator_link&utm_medium=roi_calculator_copy_link';
			const urlParams = `?currency=${selectedCurrency}&monthly=${monthlyOrders}&average=${averageOrderValue}&annual=${annualPurchaseFrequency}&lifetime=${lifetimePurchases}&insights=${insightsState}${utmParamString}`;

			// Combine the data-url and URL parameters to create the final URL
			const finalUrl = permalink + urlParams;

			// Copy the final URL to clipboard
			const textField = document.createElement('textarea');
			textField.innerText = finalUrl;
			document.body.appendChild(textField);
			textField.select();
			document.execCommand('copy');
			document.body.removeChild(textField);

			// Temporarily replace button inner HTML
			const originalHTML = calcShareButton.innerHTML;
			calcShareButton.innerHTML = 'Link copied to clipboard!';
			
			// Revert back to the original content after 3 seconds
			setTimeout(function () {
				calcShareButton.innerHTML = originalHTML;
			}, 3000);
		});



		/**
		 * 'Advanced Metrics' Hubspot form
		 */
		function hubspotForm() {
			
			var form = roiBlock.querySelector('.hs-form');
			
			if (form) {
				console.log('Form detected');

				// Add input field placeholders on the ROI Calculator hubspot form
				const textInput = form.querySelector('input[type="text"]');
				textInput.setAttribute('placeholder', 'First name');

				const emailInput = form.querySelector('input[type="email"]');
				emailInput.setAttribute('placeholder', 'Email');

				// Change the submit button text
				const hbsptSubmitButton = form.querySelector('input[type="submit"]');
				if (hbsptSubmitButton) {
					hbsptSubmitButton.setAttribute('value', 'Unlock advanced metrics');
				}

				// Setup a handler to run when the form passes validation
				form.addEventListener('hsvalidatedsubmit', function () {
					console.log('Form submitted');

					progressNode2.classList.add("completed");
					progressNode3.classList.add("active");

					// Un-blur everything
					Array.from(blurElements).forEach(element => {
						element.classList.remove('blur--active');
					});
					
					// Update data attribute for insights state on 'copy link' button
					calcShareButton.setAttribute('data-insights', 'on');
					
					// Replace form panel html
					formPanel.innerHTML = `
						<div class="roi-calculator__form-submitted-message">
							<p>Ready to start working towards these metrics?</p>
							<a href="https://loyaltylion.com/demo" class="block-button primary has-purple-background-color" target="_self" rel="noopener">Talk to sales</a>
						</div>
					`;

				});

			} else {
				setTimeout(hubspotForm, 1000); // Poll for the form every second
			}
		}
		hubspotForm();


		//// Position the '$' prefix within a right-aligned input
		const prefixInput = document.getElementById('js-roi-aov');
		const prefixSymbol = prefixInput?.previousElementSibling;

		// Ensure both the input and prefix elements exist
		if (prefixInput && prefixSymbol) {

			const adjustPrefixPosition = () => {
				const inputValue = prefixInput.value;
				const testSpan = document.createElement('span');
				testSpan.style.position = 'absolute';
				testSpan.style.visibility = 'hidden';
				testSpan.style.whiteSpace = 'nowrap';
				testSpan.style.font = window.getComputedStyle(prefixInput).font;
				testSpan.textContent = inputValue || '0';
				document.body.appendChild(testSpan);
				
				const textWidth = testSpan.offsetWidth;
				document.body.removeChild(testSpan);
				
				const inputWidth = prefixInput.offsetWidth;
				// Set position value, adjusting for 22px padding, clamping to a minimum value of 10 to keep symbol within input				
				const rightAlignPosition = Math.max(inputWidth - textWidth - 22, 10);
				
				prefixSymbol.style.left = `${rightAlignPosition}px`;
			};
			
			prefixInput.addEventListener('input', adjustPrefixPosition);
			prefixInput.addEventListener('focusout', adjustPrefixPosition);
		}


		//// Function to format commas and decimals in flagged elements
		function formatCommasAndDecimals(_obj) {
			
			if (_obj.tagName === "INPUT") {

				let inputValue = getNumber(_obj.value);

				if (isNaN(inputValue)) {					
					inputValue = 0;// Prevent field displaying 'NaN'
				}

				_obj.value = inputValue;

			} else if (totalRevenueOutput && _obj === totalRevenueOutput) {// Yearly uplift output
				let outputValue = getNumber(_obj.textContent);

				if (!isNaN(outputValue)) {
					// Format the output figure
					_obj.textContent = outputValue.toLocaleString('en-GB', { 
						minimumFractionDigits: 0,
						maximumFractionDigits: 2 
					});
				}

			}
		}
		
		//// Function to format commas and decimals in inputs
		function inputFormatOnFocusout(input) {
			let numberValue = getNumber(input.value);	

			// Default
			let decimalPlaces = 0;

			if (input.id === "js-roi-aov" || input.id === "js-roi-annual" || input.id === "js-roi-lifetime") {
				// Two decimal places
				decimalPlaces = 2;
			}

			if (isNaN(numberValue)) {					
				numberValue = 0;// Prevent field displaying 'NaN'
			}

			input.value = numberValue.toLocaleString('en-GB', { 
				minimumFractionDigits: decimalPlaces,
				maximumFractionDigits: decimalPlaces
			});
		}

		function getNumber(_str) {
			// Keep only numeric characters and at most one decimal point
			let cleaned = _str.replace(/[^0-9.]/g, '');
			let parts = cleaned.split('.');

			if (parts.length > 2) {
				// If there are multiple decimal points, keep the first one
				cleaned = parts[0] + '.' + parts.slice(1).join('');
			}

			// Allow trailing dot if the user is still typing a decimal
			if (cleaned.endsWith('.')) {
				return cleaned; // Return as a string to allow continued input
			}

			return parseFloat(cleaned); // Convert only when it's a valid number
		}

	}

	
});
