document.addEventListener("DOMContentLoaded", function () {
	const anchorNav = document.querySelector(".anchor-nav");
	const navList = anchorNav?.querySelector(".anchor-nav__list");
	const navLinks = Array.from(document.querySelectorAll(".anchor-nav__item-link"));
	const headerElement = document.querySelector(".header");

	// Abort if no anchor nav block present
	if (!anchorNav || !navList || navLinks.length === 0) return;

	const navHeight = anchorNav.offsetHeight;
	const headerHeight = headerElement?.offsetHeight || 0;
	const stickyOffset = 200;
	const preStickyOffset = navHeight + headerHeight;
	const navOriginalOffset = anchorNav.offsetTop - stickyOffset;

	const sections = navLinks
		.map(link => document.querySelector(link.getAttribute("href")))
		.filter(Boolean);

	// Sticky state toggle
	window.addEventListener("scroll", function () {
		anchorNav.classList.toggle("sticky", window.scrollY >= navOriginalOffset);
	});

	// Set active link utility
	const setActiveLink = activeLink => {
		navLinks.forEach(link => link.classList.remove("active"));
		if (activeLink) activeLink.classList.add("active");
	};

	// IntersectionObserver for scroll-based highlighting
	const observer = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			if (!entry.isIntersecting) return;

			const id = entry.target.id;
			const activeLink = navLinks.find(link => link.getAttribute("href") === `#${id}`);

			setActiveLink(activeLink);

			if (!navList.classList.contains("expanded")) {
				updateTriggerItem(activeLink);
			}
		});
	}, {
		root: null,
		rootMargin: `-${stickyOffset}px 0px -70% 0px`,
		threshold: 0
	});

	sections.forEach(section => observer.observe(section));

	// Smooth scroll with dynamic offset
	navLinks.forEach(link => {
		link.addEventListener("click", function (event) {
			event.preventDefault();

			const targetId = this.getAttribute("href").substring(1);
			const targetSection = document.getElementById(targetId);
			if (!targetSection) return;

			const isSticky = anchorNav.classList.contains("sticky") && headerElement?.classList.contains("sticky");
			const adjustedOffset = (window.scrollY === 0 && !isSticky) ? stickyOffset + preStickyOffset : stickyOffset;

			const targetPosition = targetSection.getBoundingClientRect().top + window.scrollY - adjustedOffset;

			window.scrollTo({ top: targetPosition, behavior: "smooth" });

			setActiveLink(this);

			if (navList.classList.contains("expanded")) {
				toggleMenu();
			} else {
				updateTriggerItem(this);
			}
		});
	});

	// Create mobile trigger
	const createMobileTrigger = () => {
		const triggerItem = navList.querySelector(".anchor-nav__trigger");
		if (triggerItem) return;

		const clonedItem = navList.firstElementChild.cloneNode(true);
		clonedItem.classList.add("anchor-nav__trigger");
		clonedItem.insertAdjacentHTML("beforeend", `
			<div class="anchor-nav__trigger-target"><span class="chevron"></span></div>
		`);

		navList.prepend(clonedItem);

		clonedItem.addEventListener("click", function (event) {
			if (event.target.closest(".anchor-nav__item-link")) {
				event.preventDefault();
			}
			toggleMenu();
		});
	};

	// Toggle menu open/closed
	const toggleMenu = () => {
		const isExpanded = navList.classList.toggle("expanded");
		const triggerItem = navList.querySelector(".anchor-nav__trigger");

		triggerItem?.querySelectorAll(".anchor-nav__item-link, .anchor-nav__trigger-close").forEach(el => el.remove());

		if (isExpanded) {
			const activeLink = document.querySelector(".anchor-nav__item-link.active") || navLinks[0];
			setActiveLink(activeLink);

			const closeSpan = document.createElement("span");
			closeSpan.className = "anchor-nav__trigger-close";
			closeSpan.textContent = "Close";
			closeSpan.addEventListener("click", event => {
				event.stopPropagation();
				toggleMenu();
			});

			triggerItem?.prepend(closeSpan);
		} else {
			const activeLink = document.querySelector(".anchor-nav__item-link.active") || navLinks[0];
			updateTriggerItem(activeLink);
		}
	};

	// Replace trigger label with active item
	const updateTriggerItem = activeLink => {
		const triggerItem = navList.querySelector(".anchor-nav__trigger");
		if (!triggerItem || !activeLink) return;

		triggerItem.querySelectorAll(".anchor-nav__item-link, .anchor-nav__trigger-close").forEach(el => el.remove());
		triggerItem.prepend(activeLink.cloneNode(true));
	};

	// Handle responsive trigger
	const handleResize = () => {
		const triggerItem = navList.querySelector(".anchor-nav__trigger");
		if (window.innerWidth >= 1170) {
			triggerItem?.remove();
		} else {
			if (!triggerItem) createMobileTrigger();
		}
	};

	createMobileTrigger();
	window.addEventListener("resize", handleResize);
	handleResize();
});
