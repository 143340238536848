$(document).ready(function() {
  /*****************************/
  /*** INTEGRATION PAGE TABS ***/
  /*****************************/

  if ($('.tabbed__content').length > 0) {
    function Tabs(options) {
      var tabs = document.querySelector(options.el)
      var initCalled = false
      var tabNavigation = tabs.querySelector('.tabbed__content-nav')
      var tabNavigationLinks = tabs.querySelectorAll(
        '.tabbed__content-nav__link'
      )
      var tabContentContainers = tabs.querySelectorAll('.tabbed__content__sect')

      var marker = options.marker ? createNavMarker() : false

      var activeIndex = 0

      function init() {
        if (!initCalled) {
          initCalled = true

          for (var i = 0; i < tabNavigationLinks.length; i++) {
            var link = tabNavigationLinks[i]
            clickHandlerSetup(link, i)
          }

          if (marker) {
            setMarker(tabNavigationLinks[activeIndex])
          }
        }
      }

      function clickHandlerSetup(link, index) {
        link.addEventListener('click', function(e) {
          e.preventDefault()
          goToTab(index)
        })
      }

      function goToTab(index) {
        if (
          index >= 0 &&
          index != activeIndex &&
          index <= tabNavigationLinks.length
        ) {
          tabNavigationLinks[activeIndex].classList.remove('is-active')
          tabNavigationLinks[index].classList.add('is-active')

          tabContentContainers[activeIndex].classList.remove('is-active')
          tabContentContainers[index].classList.add('is-active')

          if (marker) {
            setMarker(tabNavigationLinks[index])
          }

          activeIndex = index
        }
      }

      function createNavMarker() {
        var marker = document.createElement('div')
        marker.classList.add('tabbed__content__sect-nav-marker')
        //            tabNavigation.appendChild(marker);
        return marker
      }

      function setMarker(element) {
        marker.style.left = element.offsetLeft + 'px'
        marker.style.width = element.offsetWidth + 'px'
      }

      return {
        init: init,
        goToTab: goToTab,
      }
    }

    var m = new Tabs({
      el: '#tabs',
      marker: true,
    })

    m.init()
  }

  /*********************************/
  /*** INTEGRATION PAGE TABS END ***/
  /*********************************/

  // Blog filtering
  var $grid = $('.section__integrations__tools .right-side .row').isotope({
    // options
  })

  // Blog click making new blog section active
  $('.section__integrations__tools__left__filter li').click(function() {
    var filterValue = $(this).attr('data-filter')
    $grid.isotope({ filter: filterValue })
    $('.section__integrations__tools__left__filter .active').removeClass(
      'active'
    )
    $(this).addClass('active')
  })


})
