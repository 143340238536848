document.addEventListener('DOMContentLoaded', function() {
	/*
	* Blog posts/archives navigation
	*/

	if (document.querySelector('.blog-nav')) {
		const blogNav = document.querySelector('.blog-nav');
		const blogNavTrigger = blogNav.querySelector('.blog-nav__header-trigger');
		const blogNavList = blogNav.querySelector('.blog-nav__list');
		const body = document.body;

		if (blogNavTrigger) {
			blogNavTrigger.addEventListener('click', function(e) {
				if (window.innerWidth > 1024) return; // Prevent toggling on larger screens

				e.preventDefault();
				const isActive = blogNavList.classList.toggle('active');

				if (isActive) {
					// Calculate remaining height based on offset from top
					const offsetTop = blogNavList.getBoundingClientRect().top;
					const availableHeight = window.innerHeight - offsetTop;
					
					// Apply calculated height
					blogNavList.style.height = `${availableHeight}px`;

					body.classList.add('scroll-disabled');
				} else {
					body.classList.remove('scroll-disabled');
					blogNavList.style.height = ''; // Reset height
				}
			});
		}

		// Function to remove 'active' class if window is resized above 1000px
		function handleResize() {
			if (window.innerWidth > 1024) {
				blogNavList.classList.remove('active');
				body.classList.remove('scroll-disabled');
				blogNavList.style.height = ''; // Reset height on resize
			}
		}

		// Listen for window resize
		window.addEventListener('resize', handleResize);
	}
});
