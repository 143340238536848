$(document).ready(function() {

	let blogContent = document.querySelector('.blog');
	let relatedPosts = document.querySelectorAll('.blog-related');
	let progressBar = document.getElementById("progress-bar");

	if (blogContent && progressBar) {
		let processScroll = () => {
			// Add an offset if bar should only appear x pixels down the screen (where content starts)
			let topOffset = 0;

			// Add if there's no topOffset - contentHeight will need to be increased otherwise progress will reach 100% before bottom of content is within viewport
			let spaceAboveContent = 450;
			
			// Calculate the total height of the article body and any related content sections
			let contentHeight = blogContent.clientHeight + spaceAboveContent;
			relatedPosts.forEach(section => {
				contentHeight += section.clientHeight;
			});
			
			let viewportHeight = window.innerHeight;
			
			// Calculate the scroll progress of articleContent and related posts
			let scrollY = window.scrollY;
			let scrollPercent = ((scrollY - topOffset) / (contentHeight - viewportHeight)) * 100;

			// Limit scrollPercent to a maximum of 100%
			scrollPercent = Math.min(100, scrollPercent);
			
			// Set the scroll progress for the progressBar
			progressBar.style.setProperty("--scrollAmount", scrollPercent + '%');
	
		};

		document.addEventListener('scroll', processScroll);
	}

});
