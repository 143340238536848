jQuery( document ).ready( function ($) {

	$( '.blog-grid' ).each( function() {
		var $blogGrid = $(this);

		// Get the values from the data attributes on the blog-grid element.
		var lazyloadActive = $blogGrid.data( 'lazyload-active' );
		var currentPage = $blogGrid.data( 'page' );
		var nextPage = currentPage + 1;
		var maxPages = $blogGrid.data( 'max-pages' );
		var endPointBase = $blogGrid.data( 'endpoint' );
		var postsPerPage = $blogGrid.data( 'per-page' );
		var postType = $blogGrid.data( 'post-type' );
		var categorySlug = $blogGrid.data('category');
		var searchTerm = $blogGrid.data('search');
		
		// If there are more posts to load than are being displayed.
		if ( lazyloadActive == true && currentPage < maxPages ) {
			// Add the load more button as a sibling if not already present
			if (!$blogGrid.next('.blog-grid__loadmore').length) {
				$blogGrid.after('<a class="blog-grid__loadmore block-button has-white-background-color">Load more articles</a>');
			}
		}

		// when the 'load more' button is clicked
		$blogGrid.next( '.blog-grid__loadmore' ).click( function() {

			var loadMoreButton = $(this);

			// Add loading class to button for styling
			loadMoreButton.addClass('blog-grid__loadmore--loading');

			// Build the endpoint URL
			var endPointUrl = endPointBase + '?per_page=' + postsPerPage + '&page=' + nextPage + '&post_type=' + postType;

			// Get category if available
			if (categorySlug) {
				endPointUrl += '&category=' + categorySlug;
			}

			// Get category if available
			if (searchTerm) {
				endPointUrl += '&search=' + searchTerm;
			}

			// Ensure we exclude already loaded featured posts
			var excludeIds = [];
			$('.blog-card.featured').each(function() {
				excludeIds.push($(this).data('post-id'));// Uses data-post-id attribute from each featured blog-card
			});
		
			if (excludeIds.length) {
				endPointUrl += '&exclude_ids=' + excludeIds.join(',');
			}

			// Go and get the next posts.
			$.get( endPointUrl, function ( data ) {
				
				// Append new posts to the grid
				$blogGrid.append(data);

				// Set the new current page to the next page
                currentPage = nextPage;

				// Increment the nextpage
                nextPage++;
				
                if (nextPage > maxPages) {
					// Remove the button if no more pages
                    loadMoreButton.remove();
                } else {
					// Remove the loading class
                    loadMoreButton.removeClass('blog-grid__loadmore--loading');
                }

                $blogGrid.attr('data-page', currentPage);

			});

		});

	});

});